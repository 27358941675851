import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.slice.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5a32c1fc"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "home"
};
import Sortable from 'sortablejs'; //引入插件
import { onMounted, ref, nextTick } from 'vue';
export default {
  __name: 'test',
  setup: function setup(__props) {
    var col = ref([{
      label: '日期',
      prop: 'date'
    }, {
      label: '姓名',
      prop: 'name'
    }, {
      label: '地址',
      prop: 'address'
    }]);
    var tableData = ref([{
      date: '2016-05-03',
      name: '建筑电工',
      address: '天河区'
    }, {
      date: '2016-05-02',
      name: '管道工',
      address: '番禺区'
    }, {
      date: '2016-05-04',
      name: '木工',
      address: '越秀区'
    }, {
      date: '2016-05-01',
      name: '架子工',
      address: '海珠区'
    }]);
    onMounted(function () {
      // 阻止默认行为
      document.body.ondrop = function (event) {
        event.preventDefault();
        event.stopPropagation();
      };
      rowDrop();
      columnDrop();
    });
    //行拖拽
    var rowDrop = function rowDrop() {
      var tbody = document.querySelector('.el-table__body-wrapper tbody');
      Sortable.create(tbody, {
        onEnd: function onEnd(_ref) {
          var newIndex = _ref.newIndex,
            oldIndex = _ref.oldIndex;
          if (newIndex == oldIndex) return;
          tableData.value.splice(newIndex, 0, tableData.value.splice(oldIndex, 1)[0]);
          var newArray = tableData.value.slice(0);
          tableData.value = [];
          nextTick(function () {
            tableData.value = newArray;
          });
        }
      });
    };
    //列拖拽
    var columnDrop = function columnDrop() {
      var wrapperTr = document.querySelector('.el-table__header-wrapper tr');
      Sortable.create(wrapperTr, {
        animation: 180,
        delay: 0,
        onEnd: function onEnd(evt) {
          var oldItem = col.value[evt.oldIndex];
          col.value.splice(evt.oldIndex, 1);
          col.value.splice(evt.newIndex, 0, oldItem);
          var newArray = col.value.slice(0);
          col.value = [];
          nextTick(function () {
            col.value = newArray;
          });
        }
      });
    };
    return function (_ctx, _cache) {
      var _component_el_table_column = _resolveComponent("el-table-column");
      var _component_el_table = _resolveComponent("el-table");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_table, {
        data: tableData.value,
        style: {
          "width": "100%"
        }
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(col.value, function (item, index) {
            return _openBlock(), _createBlock(_component_el_table_column, {
              key: "col_".concat(index),
              prop: item.prop,
              label: item.label
            }, null, 8, ["prop", "label"]);
          }), 128))];
        }),
        _: 1
      }, 8, ["data"])]);
    };
  }
};